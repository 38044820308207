import React from "react"
import { FaHome, FaMapMarkerAlt, FaImages, FaListAlt, FaGift } from "react-icons/fa"
export default [
  {
    page: "Home",
    label: "Home",
    url: `/`,
    icon: <FaHome className="icon" />,
  },
  {
    page: "services",
    label: "our services",
    url: `/services`,
    icon: <FaListAlt className="icon" />,
  },

  {
    page: "gallery",
    label: "our gallery",
    url: `/gallery`,
    icon: <FaImages className="icon" />,
  },
  // {
  //   page: "specials",
  //   label: "Our Specials",
  //   url: `/specials`,
  //   icon: <FaGift className="icon" />,
  // },
  {
    page: "Location",
    label: "Our Location",
    url: `/location1`,
    icon: <FaMapMarkerAlt className="icon" />
  },
  // {
  //   page: "places",
  //   label: "Deer Park Location",
  //   url: `/location2`,
  //   icon: <FaMapMarkerAlt className="icon" />
  // },

]
